<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<NuxtLink
		:href="whatsappLink"
		external
		target="_blank"
		class="z-50 fixed bottom-6 right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all flex items-center justify-center w-20 h-20"
	>
		<Icon name="logos:whatsapp-icon" class="h-10 w-10" />
	</NuxtLink>
</template>
