<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section id="faq">
		<div
			class="max-w-screen-2xl mx-auto md:px-8 py-16 flex flex-col justify-around items-center gap-8 bg-white text-[#1C2843] px-4 italic font-redonda text-center"
		>
			<h2 class="text-[59px] font-extrabold leading-none">Ainda tem dúvidas?</h2>

			<p class="text-[33px] font-light leading-none">Estamos aqui para ajudar!</p>

			<NuxtLink
				:href="whatsappLink"
				target="_blank"
				class="px-10 py-3 text-white text-center font-black text-[32px] rounded-full shadow-slate-500 shadow-md hover:shadow-none duration-150 bg-[#1C2843] z-50"
			>
				Alguma dúvida?
			</NuxtLink>
		</div>
	</section>
</template>
