<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section id="about">
		<div class="max-w-screen-2xl mx-auto grid grid-cols-3 gap-5 bg-white bg-[url(assets/images/hexagon.png)] px-4 md:px-8 py-6">
			<div class="order-1 col-span-3 lg:col-span-2 flex flex-col gap-8 justify-center font-redonda italic text-[#1C2843] text-left z-50">
				<h2 class="text-4xl lg:text-[58px] font-extrabold leading-none mr-auto">Transforme seu negócio!</h2>

				<p class="mr-auto font-normal text-[24px] leading-tight">
					A gamificação é uma estratégia comprovada por diversos estudos e já faz parte das maiores empresas do mundo. Ela transforma o
					jeito de engajar pessoas, aumentar a produtividade e alcançar metas de forma mais leve e eficiente.
				</p>

				<p class="mr-auto font-normal text-[24px] leading-tight">
					Com o <span class="font-bold">Gamefique</span>, seu negócio ganha uma ferramenta poderosa para criar um ambiente competitivo,
					motivador e de alta performance. O ranking em tempo real mantém o time focado nos resultados, estimulando a superação constante e
					um espírito colaborativo.
				</p>
			</div>

			<div class="order-2 lg:order-4 col-span-3 sm:flex justify-center items-center">
				<NuxtLink
					:href="whatsappLink"
					target="_blank"
					class="px-12 font-extrabold italic text-white text-center text-[22px] rounded-full shadow-slate-500 shadow-md hover:shadow-none duration-150 bg-[#208c1b] z-50"
				>
					<span class="block -mb-2">Quero destravar</span> meu crescimento
				</NuxtLink>
			</div>

			<div class="order-3 flex flex-row justify-center col-span-3 lg:col-span-1 -mt-6 translate-y-6 lg:translate-y-6">
				<div class="flex grow flex-col justify-end items-center">
					<img src="~/assets/images/guy.png" class="h-96 lg:h-80 lg:scale-150 w-auto lg:self-end" alt="" />
				</div>
			</div>
		</div>
	</section>
</template>
