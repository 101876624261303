<script setup lang="ts">
useSeoMeta({
	title: 'Gamefique - Gamificação em Tempo Real para Equipes de Vendas',
	ogTitle: 'Gamefique | Ranking de Gamificação que Transforma Equipes de Vendas',
	description:
		'Gamefique é um SAAS inovador que oferece uma plataforma web de ranking de gamificação em tempo real, projetada para motivar e engajar equipes de vendas e outros times. Aumente a performance, incentive a competitividade saudável e impulsione resultados com soluções modernas de gestão de equipes.',
	ogDescription:
		'Descubra o poder da gamificação! Com o Gamefique, sua equipe de vendas tem acesso a rankings dinâmicos que estimulam a motivação e o desempenho. Experimente uma nova forma de alcançar resultados extraordinários.',
	ogImage: 'https://example.com/image.png',
	twitterCard: 'summary_large_image',
});
</script>

<template>
	<div>
		<MarketingHero />
		<MarketingHowWorks />
		<MarketingAbout />
		<MarketingSteps />
		<!-- <MarketingFeatures /> -->
		<MarketingPricing />
		<MarketingFAQ />
		<MarketingWhatsappButton />
	</div>
</template>
