<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section id="pricing">
		<div
			class="max-w-screen-2xl mx-auto bg-white px-4 md:px-8 pt-6 w-full flex flex-col justify-center items-center gap-5 text-center italic font-redonda"
		>
			<h2 class="text-[#1C2843] text-4xl lg:text-[59px] leading-none my-5 font-extrabold">Tudo por um preço acessível!</h2>

			<div class="w-full flex flex-row justify-around items-center">
				<div class="order-1 bg-gradient-to-r from-white from-25% md:from-50% to-[#ff3040] to-80% self-stretch grow" />

				<div class="order-2 bg-[#ff3040]">
					<div class="flex flex-col justify-center items-center gap-4 grow bg-[#1C2843] rounded-3xl px-24 pt-8 pb-3">
						<p class="order-1 text-[68px] font-black leading-none">START</p>

						<div class="order-2 w-full border-b-2 border-b-red-500 m-0" />

						<ul class="order-3 list-disc font-light text-[22px]">
							<li class="mb-4">Quadro Único</li>

							<li class="mb-4">Até 1000 vendedores</li>

							<li class="mb-4">Sincronização em tempo real</li>

							<li class="mb-4">Premiações personalizadas</li>

							<li>Metas individuais por vendedor</li>
						</ul>

						<p class="order-4 font-light text-[24px]">Apenas</p>

						<p class="order-5 font-black text-[69px] text-[#2AFF00] leading-none">R$87,90</p>

						<p class="order-6 font-light text-[24px]">Por Mês</p>

						<NuxtLink
							:href="whatsappLink"
							target="_blank"
							class="order-8 rounded-full px-6 py-1 font-extrabold text-[23px] text-black bg-[#2AFF00]"
						>
							Clique e saiba mais
						</NuxtLink>
					</div>
				</div>

				<div class="order-3 bg-gradient-to-l from-white from-25% md:from-50% to-[#ff3040] to-80% self-stretch grow" />
			</div>
		</div>
	</section>
</template>
