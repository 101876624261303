<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section id="steps">
		<div class="max-w-screen-2xl mx-auto w-full flex flex-col justify-start items-center bg-[#1C2843]">
			<div
				class="px-4 py-6 md:px-8 w-full flex flex-col justify-center items-center gap-5 text-center italic font-redonda bg-[#1C2843] z-10"
			>
				<h2 class="text-4xl lg:text-[60px] font-extrabold leading-none mb-4">Revolucione seu negócio de forma simples!</h2>

				<div class="w-full flex flex-row justify-around items-center">
					<div class="order-1 grow flex flex-col justify-center items-center gap-5">
						<img src="~/assets/images/check.png" class="order-1 h-[128px] w-auto" alt="" />

						<p class="order-2 text-[28px] text-center font-normal max-w-64">Assinatura do GameFique!</p>

						<p class="order-3 text-[39px] font-extrabold">1º passo</p>
					</div>

					<Icon name="mdi-light:arrow-right" class="order-2 h-24 w-24" />

					<div class="order-3 grow flex flex-col justify-center items-center gap-5">
						<img src="~/assets/images/responsive-app.png" class="order-1 h-[128px] w-auto" alt="" />

						<p class="order-2 text-[28px] text-center font-normal max-w-64">Definir metas e premiações!</p>

						<p class="text-[39px] font-extrabold order-3">2º passo</p>
					</div>

					<Icon name="mdi-light:arrow-right" class="order-4 h-24 w-24" />

					<div class="order-5 grow flex flex-col justify-center items-center gap-5">
						<img src="~/assets/images/trophy.png" class="order-1 h-[128px] w-auto" alt="" />

						<p class="order-2 text-[28px] text-center font-normal max-w-64">Destrave seu crescimento!</p>

						<p class="text-[39px] font-extrabold order-3">3º passo</p>
					</div>
				</div>

				<p class="text-[38px] font-extrabold leading-tight mt-5">
					O Gamefique pode ser personalizado de acordo<br />com as necessidades do seu negócio
				</p>

				<p class="text-[25px] font-normal">
					Implemente uma solução de gamificação única e adaptada aos seus objetivos. Entre em contato conosco hoje mesmo!
				</p>

				<NuxtLink :href="whatsappLink" target="_blank" class="rounded-full px-6 py-1 font-extrabold text-[23px] text-black bg-[#2AFF00]">
					Tire suas dúvidas!
				</NuxtLink>
			</div>
		</div>
	</section>
</template>
