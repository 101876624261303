<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section id="hero">
		<div class="max-w-screen-2xl mx-auto grid grid-cols-2 gap-5 bg-[#212c47] px-4 py-6 md:px-8">
			<div class="z-50 flex flex-col justify-center gap-5 text-left font-redonda italic col-span-2 lg:col-span-1">
				<h2 class="text-4xl md:text-[95px] leading-tight font-extrabold mr-auto">GameFique!</h2>

				<p class="mr-auto text-[25px] font-normal">
					Um sistema de <span class="font-bold">GAMIFICAÇÃO</span> para elevar a <span class="font-bold">PERFORMANCE</span> em qualquer
					área!
				</p>

				<p class="mr-auto text-[25px] font-normal">
					Seja em vendas, produção, educação ou qualquer outro setor, transforme desafios em motivação e crie uma disputa saudável que
					impulsiona resultados.
				</p>

				<div class="sm:flex items-center justify-start">
					<NuxtLink
						:href="whatsappLink"
						target="_blank"
						class="md:my-2 md:mx-6 py-1 px-10 block font-extrabold text-white text-[35px] duration-150 rounded-full shadow-inner shadow-red-900 hover:shadow-none bg-[#ff2c3c]"
					>
						Saiba mais
					</NuxtLink>
				</div>
			</div>

			<div class="relative flex flex-col justify-center items-center col-span-2 lg:col-span-1">
				<img src="~/assets/images/pc.png" class="z-40 h-96 w-auto scale-125 lg:scale-150" alt="" />

				<img src="~/assets/images/logo.png" class="absolute z-10 blur-sm size-64 right-0 lg:visible" alt="" />
			</div>
		</div>
	</section>
</template>
